import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
Strawture
Form is the essential building block of design. From it trickles sensation, reaction, emotion and language and from language flows narrative and symbol, which then flood into identity, society, the political, commercial and environmental world. Martin Venezky, from course description`}</p>
    <p>{`These are form studies for the form studio course. In this course an object was randomly assigned to each student for form explorations. Mine was straw.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog1.jpg",
        "alt": "forms_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog2.jpg",
        "alt": "forms_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog3.jpg",
        "alt": "forms_3"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog4.jpg",
        "alt": "forms_4"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog5.jpg",
        "alt": "fomrs_5"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog6.jpg",
        "alt": "fomrs_6"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog7.jpg",
        "alt": "forms_7"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog8.jpg",
        "alt": "forms_8"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog9.jpg",
        "alt": "fomrs_9"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formblog10.jpg",
        "alt": "fomrs_10"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2009/04/formstudioexhibition.jpg",
        "alt": "exhibit"
      }}></img></p>
    <p>{`Form Exhibition | California College of the Arts | 2009`}</p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      